/* A module containing functions for event handlers, UI helpers, and other useful
things. Functions that aren't exported from this module start with an underscore,
like this: _doStuff, and are at the top of the file. */

import * as constants from './constants.js';
import * as layers from './layers.js';
import map from './map.js';
import * as sources from './sources.js';

// DOM elements that we use repeatedly in this file
const _collapsibleButtons = document.getElementsByClassName('header-button');
const _colorLegend = document.getElementById('hex-colors');
const _controls = document.getElementsByClassName('control_ui');
const _consoleElement = document.getElementById('console');
const _electrification = document.getElementById('electrification');
const _hexAreaLabel = document.getElementById('hex-area');
const _hexLevelLabel = document.getElementById('hex-level');
const _layerSelect = document.getElementById('layer-select');
const _legend = document.getElementById('legend');
const _longHaulIcon = document.getElementById('svg--longhaul');
const _longhaulToggle = document.getElementById('toggle-longhaul');
const _managedIcon = document.getElementById('svg--managed');
const _managedToggle = document.getElementById('toggle-managed');
const _hexValueFilterMin = document.getElementById('hex-filter-min');
const _hexValueFilterMax = document.getElementById('hex-filter-max');
const _hexFilterHeader = document.getElementById('hex-filter-header');
const _slider = document.getElementById('slider');
const _sliderButtons = document.getElementsByClassName('slider_button');
const _sliderLabel = document.getElementsByClassName('slider_label')[0];
const _yearLabel = document.getElementById('active-year');

/** private / helper functions **/

// make a step function for class colors applying the supplied breaks
function _compileStepFunction(breaks, colors) {
    let steps = [
        'step',
        ['get', _hexAttribute],
        'rgba(0,0,0,0)',
        constants.minValueForHexDisplay,
    ];
    for (let i = 0; i < 4; i++) {
        steps.push(colors[i]);
        steps.push(breaks[i]);
    }
    steps.push(colors[4]);
    return steps;
}

// A helper function that loads the right map tiles for a given year
// year: probably a string but it doesn't matter in javascript
function _loadYear(year) {
    let dynamicTileUrl = sources.dynamicTileSource.url;
    let staticTilesUrl = sources.staticTileSource.url;

    if (year) {
        dynamicTileUrl = `${sources.dynamicTileSource.url}?yyyy=${year}`;
        staticTilesUrl = `${constants.staticTilesBase}/demand/${year}/{z}/{x}/{y}.pbf`;
    } else {
        staticTilesUrl = `${constants.staticTilesBase}/demand/100/{z}/{x}/{y}.pbf`;
    }
    map.getSource(layers.dynamicTilesLayer.source).setTiles([dynamicTileUrl]);
    map.getSource(layers.staticTilesLayer.source).setTiles([staticTilesUrl]);
    updateHexResolution();
}

// Mapbox currently blows away all of the sources and layers you added when you switch styles.
// But you can copy them over from one to another, because styles are just json objects.
// This function grabs a style json spec from a URL and copies over the sources and layers
// we added to the default style, so that it can work correctly.
// Taken from https://github.com/mapbox/mapbox-gl-js/issues/4006#issuecomment-1114095622
// styleID: the ID, in Mapbox, of the relevant style. We use constants like satelliteStyleId and RoadStyleId for these.
async function _buildStyleFromCurrent(styleID) {
    const response = await fetch(
        `https://api.mapbox.com/styles/v1/${styleID}?access_token=${constants.mapboxglToken}`
    );
    const responseJson = await response.json();
    const newStyle = responseJson;

    const currentStyle = map.getStyle();
    // ensure any sources from the current style are copied across to the new style
    newStyle.sources = Object.assign(
        {},
        currentStyle.sources,
        newStyle.sources
    );

    // find the index of where to insert our hex layers to retain in the new style
    let hexIndex = newStyle.layers.findIndex((el) => {
        return el.id == 'null-island';
    });

    // find the index of where to insert our other data layers to retain
    let extraLayerIndex = newStyle.layers.findIndex((el) => {
        return el.id == 'road-label';
    });

    // default to on top
    if (hexIndex === -1) {
        hexIndex = newStyle.layers.length;
    }

    if (extraLayerIndex == -1) {
        extraLayerIndex = newStyle.layers.length;
    }

    const hexLayers = currentStyle.layers.filter((el) => {
        return layers.baseLayers.map((x) => x.id).includes(el.id);
    });

    const otherLayers = currentStyle.layers.filter((el) => {
        return layers.extraLayers.map((x) => x.id).includes(el.id);
    });

    newStyle.layers = [
        ...newStyle.layers.slice(0, hexIndex),
        ...hexLayers,
        ...newStyle.layers.slice(hexIndex, extraLayerIndex),
        ...otherLayers,
        ...newStyle.layers.slice(extraLayerIndex, -1),
    ];
    return newStyle;
}

// Initialize the HexValue filter according to the constraints currently in the data
function _setupHexValueFilter(headerText) {
    const max =
        _categoryBreaks[colorRampType][constants.minHexResolution].at(-1);
    _hexValueFilterMin.max = max;
    _hexValueFilterMax.max = max;
    _hexValueFilterMax.value = max;
    _hexValueFilterMin.value = 0;
    if (headerText) {
        _hexFilterHeader.innerHTML = headerText;
    }
}

// It would be nice if we could handle the filter changes with the same
// function, but that causes some infinite recursion problems due to the
// way the validation has to be done.
function _filterHexValue(min, max) {
    for (let i in layers.baseLayers) {
        if (isNaN(min) || isNaN(max)) {
            // this wasn't a number. Just clear the filter and reset the UI
            // as a fallback.
            map.setFilter(layers.baseLayers[i].id, null);
            _setupHexValueFilter();
        } else {
            map.setFilter(layers.baseLayers[i].id, [
                'all',
                ['>=', ['get', _hexAttribute], min],
                ['<=', ['get', _hexAttribute], max],
            ]);
        }
    }
}

// Set the main map color legend for the hexes, depending on the range shown in the map
function _setHexColorLegend() {
    let gradient = 'background: linear-gradient(to right';
    for (let i = 0; i < 5; i++) {
        const bandColor = _categoryColors[colorRampType][i];
        const gradLine = `, ${bandColor} ${i * 20}% ${(i + 1) * 20}%`;
        gradient += gradLine;
    }
    gradient += ');';
    _colorLegend.style.cssText = gradient;
}

// Helper to build a url with parameters that reflect the current page state
function _buildCurrentStateUrlParams() {
    const params = new URLSearchParams({
        view: getBaseMapType(),
        year: slider.value,
        fullElectrification: _electrification.checked,
        layer: _layerSelect.value,
        zoom: map.getZoom(),
        center: map.getCenter().toArray(),
        longHaul: _longhaulToggle.checked,
        managed: _managedToggle.checked,
    });
    let stateURL = new URL(window.location.href);
    stateURL.search = params;
    return stateURL;
}

// Helper function to get which hex attribute should determine the map colors, and
// make it do that
function _updateHexLayer() {
    _hexAttribute = getHexAttributeFromMapState();
    const outlineStyle = [
        'step',
        ['get', _hexAttribute],
        _categoryColors['years'][0],
        constants.minValueForHexDisplay,
        '#ffffff',
    ];
    for (let i in layers.baseLayers) {
        map.setPaintProperty(
            layers.baseLayers[i].id,
            'fill-outline-color',
            outlineStyle
        );
    }
    updateHexResolution();
}

function _setBaseMapType(mapType) {
    _baseMapType = mapType;
    let hexFilterHeaderText = '';
    switch (mapType) {
        case 'power':
            document.getElementById('console').classList.add('power');
            document.getElementById('console').classList.remove('energy');

            _categoryBreaks = constants.powerCategoryBreaks;
            _categoryColors = constants.powerCategoryColors;
            hexFilterHeaderText = 'MW to Show:';
            break;
        case 'energy':
            document.getElementById('console').classList.add('energy');
            document.getElementById('console').classList.remove('power');

            _categoryBreaks = constants.energyCategoryBreaks;
            _categoryColors = constants.energyCategoryColors;
            hexFilterHeaderText = 'MWh/Day to Show:';
            break;
        default:
            _baseMapType = null;
            console.error(
                'You must choose either power or energy for the map type.'
            );
    }

    _hexAttribute = getHexAttributeFromMapState();
    _setupHexValueFilter(hexFilterHeaderText);
    _filterHexValue(
        parseInt(_hexValueFilterMin.value, 10),
        parseInt(_hexValueFilterMax.value, 10)
    );
    _setHexColorLegend();
    _updateHexLayer();
}

// Update the year slider and corresponding map filter
// increment: an integer, positive or negative, representing how much to move the slider by
function _moveYearSlider(increment) {
    clearpopups();

    const minYear = parseInt(_slider.min, 10);
    const currentYear = getCurrentYear();
    const maxYear = parseInt(_slider.max, 10);

    let desiredYear = currentYear + increment;

    if (desiredYear > maxYear || desiredYear < minYear) {
        desiredYear = currentYear;
        console.log('Hacking too much time');
    }

    // apply the filter
    _loadYear(desiredYear);

    // update text in the UI
    _slider.value = desiredYear;
    _yearLabel.innerText = desiredYear;
}

// An event handler for the 'full electrification' checkbox.
// element: the DOM element that raised the event (the checkbox in question)
function _electrificationHandler() {
    clearpopups();

    if (this.checked) {
        // disable the slider and buttons
        // and set the color ramp & tile source to full electrification
        colorRampType = 'fullElectrification';
        _loadYear();
        _slider.disabled = true;
        for (let i = 0; i < _sliderButtons.length; i++) {
            _sliderButtons[i].disabled = true;
        }
        _sliderLabel.classList.add('disabled');
        _setHexColorLegend();
    } else {
        // enable the slider and buttons
        // set the color ramp to the years type
        // and set the tile source to whatever the slider's value is
        colorRampType = 'years';
        const currentYear = getCurrentYear();
        _loadYear(currentYear);
        slider.disabled = false;
        _sliderLabel.classList.remove('disabled');

        for (let i = 0; i < _sliderButtons.length; i++) {
            _sliderButtons[i].disabled = false;
        }
        _setHexColorLegend();
    }
}

// For mobile view only, where people can show either the legend or the page controls
// This is an event handler for the button that does that.
// element: the DOM element for the button that is used to toggle this view
function _toggleLegend() {
    // toggle button text
    if (_legend.classList.contains('hide-for-small')) {
        _legend.classList.remove('hide-for-small');
        for (let i = 0; i < _controls.length; i++) {
            _controls[i].classList.add('hide-for-small');
        }
        this.innerHTML = 'Show Controls';
    } else {
        _legend.classList.add('hide-for-small');
        for (let i = 0; i < _controls.length; i++) {
            _controls[i].classList.remove('hide-for-small');
        }
        this.innerHTML = 'Show Legend';
    }
}

// For mobile view only, a button that allows people to collapse the portion
// of the UI that can show the legend / page controls.
// This is an event handler for the button that does that.
// element: the DOM element for the button that is used to toggle this view
function _toggleControls() {
    if (_consoleElement.classList.contains('collapsed')) {
        _consoleElement.classList.remove('collapsed');
        this.setAttribute('title', 'Close this Panel');
        this.classList.remove('arrow--up');
        this.classList.add('arrow--down');
    } else {
        _consoleElement.classList.add('collapsed');
        this.setAttribute('title', 'Open this Panel');
        this.classList.add('arrow--up');
        this.classList.remove('arrow--down');
    }

    // the contols use a CSS transition that takes time to resize, so
    // resize the map after we know that is finished
    const center = map.getCenter();
    setTimeout(function () {
        map.resize();
        map.setCenter(center);
    }, 510);
}

// A helper for _toggleStyle - sets the correct opacities for the hex layers
// when switching between road and satellite views.
// It's interesting to note that the hex layers are LESS opaque in satellite
// views, while the data layers are MORE opaque. This is to do with their position in
// the stack of things we're looking at vs the colors of the background map.
function _toggleLHexLayerOpacitiesForStyle(opacity) {
    map.setPaintProperty(
        layers.staticTilesLayer.id,
        'fill-opacity',
        opacity
    ).setPaintProperty(layers.dynamicTilesLayer.id, 'fill-opacity', [
        'step',
        ['zoom'],
        0,
        constants.staticHexTilesMaxZoom,
        opacity,
    ]);
}

// A helper for _toggleStyle - sets the correct opacities for the static
// tile layers when switching between road and satellite views.
function _toggleStaticTileOpacitiesForStyle(opacity) {
    for (const layer of layers.staticPolygonLayers) {
        map.setPaintProperty(layer.id, 'fill-opacity', opacity);
    }
}

// A handler for the view toggle button on the map, which switches between
// OSM road and satellite views.
// element: the DOM element for the button that is used to toggle the map style
function _toggleStyle() {
    const currentName = map.getStyle().name;
    let hexOpacity, staticOpacity;

    if (currentName == constants.roadStyleName) {
        hexOpacity = constants.satelliteHexFillOpacity;
        staticOpacity = constants.satelliteFillOpacity;
        this.innerHTML = 'Default View';
        // now fetch our satellite style and put the layers and sources in that too
        _buildStyleFromCurrent(constants.satelliteStyleId).then((res) => {
            map.setStyle(res);
        });
    } else {
        this.innerHTML = 'Satellite View';
        hexOpacity = constants.roadHexFillOpacity;
        staticOpacity = constants.defaultFillOpacity;
        _buildStyleFromCurrent(constants.roadStyleId).then((res) => {
            map.setStyle(res);
        });
    }

    // apply any filters / settings from the control panel, once the style is loaded
    map.once('style.load', () => {
        // set our layer opacities accordingly - must be done after the style loads
        _toggleLHexLayerOpacitiesForStyle(hexOpacity);
        _toggleStaticTileOpacitiesForStyle(staticOpacity);
        _electrificationHandler.call(_electrification); // will load year if appropriate
        _filterHexValue(
            parseInt(_hexValueFilterMin.value, 10),
            parseInt(_hexValueFilterMax.value, 10)
        );
    });
}

// A handler for the long haul off / on toggle switch
function _toggleLongHaul() {
    clearpopups();
    if (this.checked) {
        this.classList.add('filter-toggle--active');
        _longHaulIcon.classList.add('filter-icon--active');
    } else {
        this.classList.remove('filter-toggle--active');
        _longHaulIcon.classList.remove('filter-icon--active');
    }
    _updateHexLayer();
}

// A handler for the managed charging toggle switch
function _toggleManaged() {
    clearpopups();
    if (this.checked) {
        this.classList.add('filter-toggle--active');
        _managedIcon.classList.add('filter-icon--active');
    } else {
        this.classList.remove('filter-toggle--active');
        _managedIcon.classList.remove('filter-icon--active');
    }
    _updateHexLayer();
}

// For desktop view only; people can expand sections of the control panel on the left
// This is a handler for the expandable text headers that allow this
// element: the DOM Element that is used to expand or collapse this view (e.g. "Map Instructions")
function _accordion() {
    // Cast the expanded state as a boolean
    let expanded =
        this.getAttribute('aria-expanded') === 'false' ? false : true;

    // find the element to expand / contract
    let expandable = document.getElementById(
        this.getAttribute('aria-controls')
    );

    // Switch the states of aria-expanded and aria-hidden
    this.setAttribute('aria-expanded', !expanded);
    expandable.setAttribute('aria-hidden', expanded);

    // Switch the appearance of the expandable element
    if (expanded) {
        expandable.classList.add('hidden');
    } else {
        expandable.classList.remove('hidden');
    }
}

// A function to filter the minimun HexValue to show hexes for
function _filterMinHexValue() {
    // This is a minimum value to filter on, so it needs to be smaller
    // than the number in the other box
    const max = parseInt(_hexValueFilterMax.value, 10);
    this.max = max;

    const valid = this.checkValidity();
    if (valid) {
        let value = parseInt(this.value, 10);

        // if it's not a number, clear the filter.
        if (isNaN(value)) {
            value = parseInt(this.min, 10);
            this.value = value;
        }
        _filterHexValue(value, max);
    }
    this.reportValidity();
}

// A function to filter the maximum HexValue to show hexes for
function _filterMaxHexValue() {
    // This is a maximum value to filter on, so it needs to be larger
    // than the number in the other box.
    const min = parseInt(_hexValueFilterMin.value, 10);
    this.min = min;

    const valid = this.checkValidity();
    if (valid) {
        let value = parseInt(this.value, 10);

        // if it's not a number, clear the filter.
        if (isNaN(value)) {
            value = parseInt(this.max, 10);
            this.value = value;
        }
        _filterHexValue(min, value);
    }
    this.reportValidity();
}

// A function to show the relevant data layer (in extraLayers)
// This is the selection handler for the layer switcher.
function _showLayer() {
    clearpopups();
    // show the selected layer, hide all the other layers
    for (let x in layers.extraLayers) {
        const layer = layers.extraLayers[x].id;

        if (!!this.value && layer.startsWith(this.value)) {
            _legend.getElementsByClassName(layer)[0].classList.remove('hidden');
            map.setLayoutProperty(layer, 'visibility', 'visible');
            if (layer == layers.utilityServiceAreasLayer.id) {
                // set its opacity to the one we want to show
                map.setPaintProperty(
                    layer,
                    'fill-opacity',
                    constants.utilityServiceAreaOpacity
                );
            }
        } else {
            _legend.getElementsByClassName(layer)[0].classList.add('hidden');
            if (layer == layers.utilityServiceAreasLayer.id) {
                // set its opacity to 0 instead so we can still query its features
                map.setPaintProperty(layer, 'fill-opacity', 0);
            } else {
                map.setLayoutProperty(layer, 'visibility', 'none');
            }
        }
    }
}

// Helper to generate a url for the current view and copy it to the user's clipboard
function _copyShareLink() {
    const text = document.getElementById('share-text');
    const message = text.innerHTML;
    const successClass = 'action_button--success';
    const shareURL = _buildCurrentStateUrlParams().toString();
    history.replaceState({}, '', shareURL);
    navigator.clipboard.writeText(shareURL);

    text.innerHTML = 'Link Copied';
    this.classList.add(successClass);

    // display a success message for 5 seconds
    setTimeout(
        function (button) {
            text.innerHTML = message;
            button.classList.remove(successClass);
            button.blur();
        },
        5000,
        this
    );
}

/* State variables */

// store state of the full electrification checkbox
let colorRampType = 'years';

// base map variables
let _baseMapType, _categoryBreaks, _categoryColors, _hexAttribute;

/* Public / exported functions start here. */

// A helper to see if the page has finished loading.
function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
        return;
    }
    document.addEventListener('DOMContentLoaded', fn);
}

// helper to clear popups on the map
function clearpopups() {
    document.querySelectorAll('.mapboxgl-popup').forEach((e) => e.remove());
}

// Dismisses the instructional overlay that appears on page load
function dismissInstructions() {
    document.getElementById('instructions').classList.add('hidden');
    document.body.classList.remove('no-scroll');
}

// Right now, our choices are 'power' or 'energy'. I don't want this to get
// set willy-nilly, or more than once ever.
function getBaseMapType() {
    return _baseMapType;
}

// Generates the data hex attribute to show in the map from the current
// combination of the base map type, long haul, and managed / unmanaged filter state.
function getHexAttributeFromMapState() {
    const mapType = getBaseMapType();
    let lh = '_no_lh';
    if (longHaulIncluded()) {
        lh = '_total';
    }

    let power = '';

    if (mapType == 'power') {
        power = '_unmanaged';
        if (_managedToggle.checked) {
            power = '_managed';
        }
    }

    return `${mapType}${power}${lh}`;
}

// Tell us what hex resolution to assume the tiles contain,
// based on the zxy slippy map zoom level
function hexResFromZoom() {
    const z = map.getZoom();
    for (let i in constants.hexResolutionBreaks) {
        if (z < constants.hexResolutionBreaks[i].zoom) {
            return constants.hexResolutionBreaks[i].hex;
        }
    }
    return constants.hexResolutionBreaks.at(-1).hex;
}

// Update the information text about hexagon area as well as map colors based
// on zoom level (which determines hex resolution) and ramp type
function updateHexResolution() {
    const hexRes = hexResFromZoom();
    if (hexRes in constants.hexAreas) {
        _hexLevelLabel.innerText = hexRes;
        _hexAreaLabel.innerText = constants.hexAreas[hexRes];
    }

    const breakPoints = _categoryBreaks[colorRampType][hexRes];
    for (let i = 1; i < 6; i++) {
        document.getElementsByClassName('bin' + i)[0].innerText =
            breakPoints[i - 1].toLocaleString('en-US');
    }
    const steps = _compileStepFunction(
        breakPoints,
        _categoryColors[colorRampType]
    );
    map.setPaintProperty(layers.staticTilesLayer.id, 'fill-color', steps);
    map.setPaintProperty(layers.dynamicTilesLayer.id, 'fill-color', steps);
}

// Set the hosting capacity color legend for the lines
function setHostingLegend() {
    let gradient = 'background: linear-gradient(to right';
    for (let i = 0; i < constants.hostingCapacityColors.length; i++) {
        const bandColor = constants.hostingCapacityColors[i];
        const gradLine = `, ${bandColor} ${i * 25}% ${(i + 1) * 25}%`;
        gradient += gradLine;
    }
    gradient += ');';
    document.getElementById('hosting_capacity-colors').style.cssText = gradient;
}

// Set the PM 2.5 and other color legends
// todo: if this becomes stepped rather than a smooth gradient, turn this and the
// two functions above it into one function that takes 2 parameters.
function setLayerGradientColorLegends() {
    let gradient = 'background: linear-gradient(to right, #FFFFFF 0';
    for (let i = 0; i < constants.layerGradientColors.length; i++) {
        const bandColor = constants.layerGradientColors[i] + 'CC';
        const gradLine = `, ${bandColor} ${(i + 1) * 50}%`;
        gradient += gradLine;
    }
    gradient += ');';
    const colorElements = document.getElementsByClassName(
        'layer-gradient-colors'
    );
    for (let i = 0; i < colorElements.length; i++) {
        colorElements.item(i).style.cssText = gradient;
    }
}

// Helper to hook up the event handlers in this file to their respective UI elements
function setupEventHandlers() {
    document.getElementById('toggle-style').onclick = _toggleStyle;
    document.getElementById('toggle-legend').onclick = _toggleLegend;
    document.getElementById('toggle-controls').onclick = _toggleControls;

    document.getElementById('landing-button').onclick = function () {
        dismissInstructions();
    };

    document.getElementById('energy-select').onclick = function () {
        _setBaseMapType('energy');
    };
    document.getElementById('power-select').onclick = function () {
        _setBaseMapType('power');
    };

    _slider.onmouseup = function () {
        _moveYearSlider(0);
    };
    document.getElementById('slider_back').onclick = function () {
        _moveYearSlider(-1);
    };
    document.getElementById('slider_forward').onclick = function () {
        _moveYearSlider(1);
    };
    document.getElementById('share-button').onclick = _copyShareLink;

    _longhaulToggle.onclick = _toggleLongHaul;
    _managedToggle.onclick = _toggleManaged;
    _electrification.onclick = _electrificationHandler;
    _hexValueFilterMin.onfocusout = _filterMinHexValue;
    _hexValueFilterMin.onchange = _filterMinHexValue;
    _hexValueFilterMax.onfocusout = _filterMaxHexValue;
    _hexValueFilterMax.onchange = _filterMaxHexValue;
    _layerSelect.onchange = _showLayer;
    Array.from(_collapsibleButtons).forEach(function (el) {
        el.onclick = _accordion;
    });
}

// Helper to apply url parameters to UI elements
function applyParamValues(cleanedParams) {
    // let's make our default map base type energy in case we end up without one somehow
    if (cleanedParams.hasOwnProperty('view')) {
        _setBaseMapType(cleanedParams['view']);
    } else {
        _setBaseMapType('energy');
    }

    // initialise the year slider (or set the year if the page param indicates)
    if (cleanedParams.hasOwnProperty('year')) {
        const year = parseInt(cleanedParams['year'], 10);
        slider.value = year;
        _loadYear(year);
    }
    _moveYearSlider(0); // This sets up some necessary stuff

    // if the page parameter indicates, check the 'full electrification' box
    // and call its event handler
    if (
        cleanedParams.hasOwnProperty('fullElectrification') &&
        cleanedParams['fullElectrification'] == 'true'
    ) {
        _electrification.checked = true;
        _electrificationHandler.apply(_electrification);
    }

    // if the page parameter indicates, select a layer and call the
    // event handler
    if (cleanedParams.hasOwnProperty('layer')) {
        _layerSelect.value = cleanedParams['layer'];
        _showLayer.apply(_layerSelect);
    }

    // if the page parameter indicates, exclude long haul trucks
    if (cleanedParams.hasOwnProperty('longHaul')) {
        _longhaulToggle.checked = cleanedParams['longHaul'] == 'true';
        _toggleLongHaul.apply(_longhaulToggle);
    }

    // if the page parameter indicates, show managed charging scenarios
    if (cleanedParams.hasOwnProperty('managed')) {
        _managedToggle.checked = cleanedParams['managed'] == 'true';
        _toggleManaged.apply(_managedToggle);
    }

    // if the page parameter indicates that we are being embedded by GridFast,
    // filter out all of the hexes in the hex layer by setting both the min
    // and max to 0.
    if (
        cleanedParams.hasOwnProperty('gridFast') &
        (cleanedParams['gridFast'] == 'true')
    ) {
        _filterHexValue(0, 0);
    }
}

// Helper to get the current value of the year slider, as an integer
function getCurrentYear() {
    return parseInt(_slider.value, 10);
}

// Helper to get the state of the long haul toggle
function longHaulIncluded() {
    return _longhaulToggle.checked;
}

// Helper to get the state of the full electrification checkbox
function fullElectrificationChecked() {
    return _electrification.checked;
}

export {
    applyParamValues,
    clearpopups,
    colorRampType,
    dismissInstructions,
    fullElectrificationChecked,
    getBaseMapType,
    getCurrentYear,
    getHexAttributeFromMapState,
    hexResFromZoom,
    longHaulIncluded,
    ready,
    setHostingLegend,
    setLayerGradientColorLegends,
    setupEventHandlers,
    updateHexResolution,
};
